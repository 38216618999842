<template>
  <div class="statistics w-100 h-100 d-flex flex-column align-items-center">
    <base-header
      :title="$t('數據紀錄')"
      :is-title-bold="true"
      :left-arrow="true"
      :back-on-click-left="true"
      :breadcrumb="breadcrumb"
      :mode="$device.isMobile ? 'secondary' : 'primary'"
    ></base-header>
    <loading-wrapper v-if="isPageLoading"></loading-wrapper>

    <div v-if="!isEmpty" class="statistics-container w-100 scroll-bar">
      <div class="statistics-wrap">
        <div class="card-wrap">
          <div
            v-for="(statistic, index) in statistics"
            :key="statistic.title"
            :class="{'mb-4': index !== statistics.length - 1}"
          >
            <div
              class="stat-title title bg-bgsection text-18 font-weight-bold d-flex justify-content-between align-items-center pl-3"
              :class="$isJungo ? 'text-secondary' : 'text-primary '"
            >
              {{ statistic.title }}
            </div>
            <div
              v-for="stat in statistic.statistic"
              :key="stat.subtitle"
              class="list-wrapper"
            >
              <div
                class="list border-bottom d-flex justify-content-between align-items-center"
              >
                <div
                  class="stat-list list-left d-flex justify-content-between align-items-center pl-3"
                >
                  <span>{{ stat.subtitle }}：</span>
                  <div v-if="statistic.type === 'aiGame'">
                    <span
                      >{{ $t('{winCount}勝', {winCount: stat.winCount}) }}
                    </span>
                    <span class="mr-1"
                      >{{
                        $t('{loseCount}敗', {
                          loseCount: stat.totalCount - stat.winCount,
                        })
                      }}
                    </span>
                    <span
                      >{{
                        $t('勝率{winRate}%', {
                          winRate: Math.round(stat.winRate * 100),
                        })
                      }}
                    </span>
                  </div>
                  <div v-else>
                    <span
                      >{{ $t('累計{winCount}題', {winCount: stat.totalCount}) }}
                    </span>
                    <span
                      >{{
                        $t('正確率{winRate}%', {
                          winRate: Math.round(stat.winRate * 100),
                        })
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import LoadingWrapper from '@/components/Base/LoadingWrapper.vue';
import _ from 'lodash';

export default {
  components: {
    BaseHeader,
    LoadingWrapper,
  },
  data() {
    return {
      isPageLoading: false,
      breadcrumb: [
        {
          name: this.$t('個人'),
          enable: true,
          router: 'personal',
        },
        {
          name: this.$t('數據記錄'),
          enable: true,
          router: 'statistics',
        },
      ],
    };
  },
  computed: {
    isEmpty() {
      return _.isEmpty(this.userStats);
    },
    userStats() {
      return this.$store.getters['user/userStatistics'];
    },
    statistics() {
      return [
        {
          type: 'aiGame',
          title: this.$t('下棋'),
          statistic: [
            {
              subtitle: this.$t('課程'),
              totalCount: this.userStats?.course?.game.count,
              winCount: this.userStats?.course?.game.winCount,
              winRate: this.userStats?.course?.game.winRate,
            },
            {
              subtitle: this.$t('練習'),
              totalCount: this.userStats?.practice?.game.count,
              winCount: this.userStats?.practice?.game.winCount,
              winRate: this.userStats?.practice?.game.winRate,
            },
            {
              subtitle: this.$t('對戰'),
              totalCount: this.userStats?.pvp?.game.count,
              winCount: this.userStats?.pvp?.game.winCount,
              winRate: this.userStats?.pvp?.game.winRate,
            },
            {
              subtitle: this.$t('檢定'),
              totalCount: this.userStats?.verification?.game.count,
              winCount: this.userStats?.verification?.game.winCount,
              winRate: this.userStats?.verification?.game.winRate,
            },
          ],
        },
        {
          type: 'tsumeGame',
          title: this.$t('做題'),
          statistic: [
            {
              subtitle: this.$t('課程'),
              totalCount: this.userStats.course.tsume.count,
              winCount: this.userStats.course.tsume.winCount,
              winRate: this.userStats.course.tsume.winRate,
            },
            {
              subtitle: this.$t('練習'),
              totalCount: this.userStats.practice.tsume.count,
              winCount: this.userStats.practice.tsume.winCount,
              winRate: this.userStats.practice.tsume.winRate,
            },
            {
              subtitle: this.$t('檢定'),
              totalCount: this.userStats.verification.tsume.count,
              winCount: this.userStats.verification.tsume.winCount,
              winRate: this.userStats.verification.tsume.winRate,
            },
          ],
        },
      ];
    },
  },
  async created() {
    try {
      this.isPageLoading = true;
      await this.$store.dispatch('user/getUserStatistics');
    } catch {
      this.$Message.error(this.$t('發生未知錯誤，再試一次'));
    } finally {
      this.isPageLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.statistics {
  overflow: overlay;
}
.statistics-container {
  padding: 16px;
  .stat-title {
    height: 40px;
    border-radius: 10px;
  }
  .stat-list {
    height: 51px;
  }
}

@media screen and (min-width: 768px) {
  .statistics {
    box-shadow: 0px 2px 7px 0px #c7c7c74d;
  }
  .statistics-container {
    padding: 0px;
    height: calc(100vh - 80px);
    overflow: overlay;
    .statistics-wrap {
      max-width: 100%;
      margin: 40px auto;
      padding: 0 32px;
    }
    .card-wrap {
      background: white;
      padding: 24px 40px;
      box-shadow: 0px 2px 7px rgba(199, 199, 199, 0.3);
      border-radius: 10px;
    }
  }
}

@media screen and (min-width: 1280px) {
  .statistics-container {
    .statistics-wrap {
      max-width: 1172px;
    }
  }
}
</style>
